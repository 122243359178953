import axios from "axios";
import _ld from "lodash";
import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Container } from "semantic-ui-react";
import "./app.scss";
import CivilStatus from "./component/civilStatus/civilStatus";
import Company from "./component/company/company";
import Export from "./component/export/export";
import Header from "./component/header/header";
import Health from "./component/health/health";
import Technical from "./component/technical/technical";
import { CustomerContext } from "./customer-context";

class App extends Component {
  constructor(props) {
    super(props);

    this.toggleCustomer = value => {
      this.setState({
        customer_id: value
      });
    };
    this.toggleCompany = value => {
      this.setState({
        company_id: value
      });
    };
    this.goToCivil = value => {
      this.setState({
        redirectToCivil: value
      });
    };
    this.resetCurrentView = value => {
      this.setState({
        resetView: value
      });
    };
    this.state = {
      company_id: 0,
      customer_id: 0,
      redirectToCivil: false,
      toggleCustomer: this.toggleCustomer,
      toggleCompany: this.toggleCompany,
      goToCivil: this.goToCivil,
      resetCurrentView: this.resetCurrentView,
      resetView: false,
      user: []
    };
  }

  componentDidMount() {
    this.checkLogin();
  }

  // Check if the user is connected
  checkLogin = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/user`).then(response => {
      if (_ld.isEmpty(response.data)) {
        document.location.href = `${process.env.REACT_APP_LOGIN_URL}`;
      } else {
        this.setState({
          user: {
            name: response.data.name,
            app: response.data.app,
            groups: response.data.groups,
            admin: response.data.admin
          }
        });
      }
    });
  };

  render() {
    return (
      <CustomerContext.Provider value={this.state}>
        <Container
          fluid={true}
          className={
            this.state.user.name === undefined
              ? "content--container__hidden"
              : "content--container"
          }
        >
          <Router>
            <div>
              <header className="header">
                <Header />
              </header>
              <section>
                <article className="article">
                  <Switch>
                    <Route exact path="/">
                      <Company company_id={this.state.company_id} />
                    </Route>
                    <Route exact path="/civil">
                      <CivilStatus customer_id={this.state.customer_id} />
                    </Route>
                    <Route exact path="/health">
                      <Health customer_id={this.state.customer_id} />
                    </Route>
                    <Route exact path="/export">
                      <Export />
                    </Route>
                    <Route exact path="/technical">
                      <Technical />
                    </Route>
                  </Switch>
                </article>
              </section>
            </div>
          </Router>
        </Container>
      </CustomerContext.Provider>
    );
  }
}

export default App;
