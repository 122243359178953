import { CustomerContext } from "customer-context.js";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "./navbar.scss";
// import rouage from "./rouage.png";

class Navbar extends Component {
  doc = () => {
    document.location.href = `${process.env.REACT_APP_API_URL}/admin/filer/folder/`;
  };

  hasDocRight = (match, location) => {
    if (
      this.context.user.groups &&
      this.context.user.groups.indexOf("doc") > -1
    )
      return true;
    return false;
  };

  hasTechnicalRight = () => {
    return (
      this.context.user.groups &&
      this.context.user.groups.indexOf("technique") > -1
    );
  };

  hasExportRight = (match, location) => {
    if (
      this.context.user.groups &&
      this.context.user.groups.indexOf("export") > -1
    )
      return true;
    return false;
  };

  hasRight = (match, location) => {
    if (
      this.context.user.groups &&
      this.context.user.groups.indexOf("health") > -1
    )
      return true;
    return false;
  };

  render() {
    return (
      <div className="menu">
        <NavLink
          to="/civil"
          activeClassName="menu--link__active"
          className="menu--link-civil menu--link menu--link__hover"
        >
          Administratif
        </NavLink>
        <NavLink
          to="/technical"
          activeClassName="menu--link__active"
          className={
            this.hasTechnicalRight()
              ? "menu--link-technical menu--link menu--link__hover "
              : "menu--link__hide"
          }
        >
          Technique
        </NavLink>
        <NavLink
          to="/health"
          activeClassName="menu--link__active"
          className={
            this.hasRight()
              ? "menu--link-health menu--link menu--link__hover "
              : "menu--link-health menu--link menu--link__hover menu--link__hide"
          }
        >
          Médical
        </NavLink>
        <NavLink
          to="/export"
          activeClassName="menu--link__active"
          className={
            this.hasExportRight()
              ? "menu--link-export menu--link menu--link__hover "
              : "menu--link-export menu--link menu--link__hover menu--link__hide"
          }
        >
          Export
        </NavLink>
        <NavLink
          to="#"
          onClick={this.doc}
          activeClassName="menu--link__active"
          className={
            this.hasDocRight()
              ? "menu--link-doc menu--link menu--link__hover "
              : "menu--link-doc menu--link menu--link__hover menu--link__hide"
          }
        >
          Documents
        </NavLink>
      </div>
    );
  }
}

Navbar.contextType = CustomerContext;

export default Navbar;
