import React, { Component } from "react";
import { Label } from "semantic-ui-react";
import "./labelColor.scss";

class LabelColor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.name,
      content: props.children
    };
  }

  componentWillReceiveProps(newProps, a) {
    this.setState({ content: newProps.children });
  }

  render() {
    return (
      <div>
        <Label className="label--text">{this.state.name}</Label>
        <Label className="label--content">{this.state.content}</Label>
      </div>
    );
  }
}

export default LabelColor;
