import axios from "axios";
import FolderViewer from "component/folderViewer/folderViewer";
import { CustomerContext } from "customer-context.js";
import _ld from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Divider, Grid, Icon, Label, Menu, Segment } from "semantic-ui-react";
import LabelColor from "../labelColor/labelColor";
import "./health.scss";

class Health extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      activeItem: "suivi",
      doc: [],
      setupData: false
    };
  }

  componentWillUpdate(prevState, nextState) {
    if (
      prevState.customer_id !== this.context.customer_id &&
      this.context.customer_id > 0
    ) {
      this.setState({
        setupData: false
      });
    }
  }

  componentDidMount() {
    this.getUserData();
  }

  getUserData = () => {
    let customer_id = this.context.customer_id;
    if (customer_id > 0) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/health/${customer_id}`)
        .then(response => {
          if (!_ld.isEmpty(response.data)) {
            this.setState({ data: response.data });
          }
        });
      axios
        .get(`${process.env.REACT_APP_API_URL}/doc-health/${customer_id}`)
        .then(response => {
          if (!_ld.isEmpty(response.data)) {
            this.setState({ doc: response.data, setupData: true });
          } else {
            this.setState({ doc: [], setupData: true });
          }
        });
    }
  };
  handleClick = itemName => {
    this.context.goToCivil(false);
    this.setState({ activeItem: itemName });
  };

  getTextOrNA = text => {
    return text === null || text === "" || text === undefined ? "NA" : text;
  };

  getDateValidOrNA = date => {
    if (moment(date).isValid()) {
      return moment(date).format("DD/MM/YYYY");
    }
    return "NA";
  };

  getLinkText = data => {
    if (String(data).indexOf("/") > -1) {
      return String(data)
        .split("/")
        .slice(-1);
    } else {
      return "Aucun fichier";
    }
  };

  resetView = () => {
    this.setState({
      activeItem: "suivi"
    });
  };

  render() {
    if (this.context.customer_id === 0) {
      return <Redirect to="/" />;
    } else if (!this.state.setupData) {
      this.getUserData();
    }
    const { activeItem } = this.state;

    return (
      <div className="content--health">
        <Grid className="content-grid-health" padded={true}>
          <Grid.Column width={4}>
            <Menu fluid vertical tabular id="menu-vertical">
              <Menu.Item
                name="suivi"
                active={activeItem === "suivi"}
                onClick={() => this.handleClick("suivi")}
                icon={`user circle`}
                className={"subMenu--icon"}
              />
              <Menu.Item
                name="visitesMedicales"
                active={activeItem === "visitesMedicales"}
                className={"subMenu--icon"}
                icon={`stethoscope`}
                onClick={() => this.handleClick("visitesMedicales")}
              />
              <Menu.Item
                name="historique"
                active={activeItem === "historique"}
                className={"subMenu--icon"}
                icon={`history`}
                onClick={() => this.handleClick("historique")}
              />
              <Menu.Item
                name="document"
                active={activeItem === "document"}
                icon={`tasks`}
                className={
                  this.context.user.groups.indexOf("doc-health") > -1
                    ? "subMenu--icon"
                    : "content-grid--content__hidden"
                }
                onClick={() => this.handleClick("document")}
              />
            </Menu>
          </Grid.Column>
          <Grid.Column stretched width={12}>
            {activeItem === "suivi" && (
              <Segment className="content-grid--content">
                <Grid>
                  <Grid.Row columns={1}>
                    <Grid.Column>
                      <div
                        className={
                          String(this.state.data["fmu_doc__file"]).indexOf(
                            "#"
                          ) === -1
                            ? ""
                            : "content-grid--content__hidden"
                        }
                      >
                        <LabelColor name="Fiche Médicale d'Urgence">
                          <a
                            href={this.state.data["fmu_doc__file"]}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="health-file"
                          >
                            <Icon name="file outline" />
                            {this.getLinkText(this.state.data["fmu_doc__file"])}
                          </a>
                        </LabelColor>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                  {this.context.user.groups.indexOf("suivi") > -1 && (
                    <Grid>
                      <Grid.Row columns={3}>
                        <Grid.Column>
                          <LabelColor name="Vaccination">
                            {this.state.data["vaccinnation"] ? (
                              <Icon size="small" color="green" name="check" />
                            ) : (
                              <Icon size="small" color="red" name="dont" />
                            )}
                          </LabelColor>
                          <div className="health-associated-file">
                            <a
                              className={
                                String(
                                  this.state.data["vaccinnation_doc__file"]
                                ).indexOf("#") === -1
                                  ? "content-grid--content"
                                  : "content-grid--content__hidden"
                              }
                              href={this.state.data["vaccinnation_doc__file"]}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Label>
                                <Icon name="file outline" />
                                {this.getLinkText(
                                  this.state.data["vaccinnation_doc__file"]
                                )}
                              </Label>
                            </a>
                          </div>
                        </Grid.Column>
                        <Grid.Column>
                          <LabelColor name="PAI">
                            {this.state.data["pai"] ? (
                              <Icon size="small" color="green" name="check" />
                            ) : (
                              <Icon size="small" color="red" name="dont" />
                            )}
                          </LabelColor>
                          <div className="health-associated-file">
                            <a
                              className={
                                String(
                                  this.state.data["pai_doc__file"]
                                ).indexOf("#") === -1
                                  ? "content-grid--content"
                                  : "content-grid--content__hidden"
                              }
                              href={this.state.data["pai_doc__file"]}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Label>
                                <Icon name="file outline" />
                                {this.getLinkText(
                                  this.state.data["pai_doc__file"]
                                )}
                              </Label>
                            </a>
                          </div>
                        </Grid.Column>
                        <Grid.Column>
                          <LabelColor name="Traitement">
                            {this.state.data["treatment"] ? (
                              <Icon size="small" color="green" name="check" />
                            ) : (
                              <Icon size="small" color="red" name="dont" />
                            )}
                          </LabelColor>
                          <div className="health-associated-file">
                            <a
                              className={
                                String(
                                  this.state.data["treatment_doc__file"]
                                ).indexOf("#") === -1
                                  ? "content-grid--content"
                                  : "content-grid--content__hidden"
                              }
                              href={this.state.data["treatment_doc__file"]}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Label>
                                <Icon name="file outline" />
                                {this.getLinkText(
                                  this.state.data["treatment_doc__file"]
                                )}
                              </Label>
                            </a>
                          </div>
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row columns={3}>
                        <Grid.Column>
                          <LabelColor name="Psychologique">
                            {this.state.data["psy"] ? (
                              <Icon size="small" color="green" name="check" />
                            ) : (
                              <Icon size="small" color="red" name="dont" />
                            )}
                          </LabelColor>
                        </Grid.Column>
                        <Grid.Column>
                          <LabelColor name="Orthophoniste">
                            {this.state.data["ortho"] ? (
                              <Icon size="small" color="green" name="check" />
                            ) : (
                              <Icon size="small" color="red" name="dont" />
                            )}
                          </LabelColor>
                        </Grid.Column>
                        <Grid.Column>
                          <LabelColor name="Psychomoteur">
                            {this.state.data["psychomoteur"] ? (
                              <Icon size="small" color="green" name="check" />
                            ) : (
                              <Icon size="small" color="red" name="dont" />
                            )}
                          </LabelColor>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  )}
                </Grid>
              </Segment>
            )}
            {activeItem === "visitesMedicales" && (
              <Segment className="content-grid--content">
                <Grid>
                  <Grid.Row centered className="content--health__header">
                    {this.state.data &&
                    this.state.data.visit &&
                    this.state.data.visit.length > 0 ? (
                      ""
                    ) : (
                      <h3>Aucun historique de visite</h3>
                    )}
                  </Grid.Row>
                  {typeof this.state.data["visit"] === "object" &&
                    Object.values(this.state.data.visit).map(d => (
                      <div className="grid-wrapper" key={Math.random()}>
                        <Grid>
                          <Grid.Row columns={4}>
                            <Grid.Column>
                              <LabelColor name="Visite médicale">
                                {this.getDateValidOrNA(d["health_visit_last"])}
                              </LabelColor>
                            </Grid.Column>
                            <Grid.Column>
                              <LabelColor name="Prochaine visite">
                                {this.getDateValidOrNA(d["health_visit_next"])}
                              </LabelColor>
                            </Grid.Column>
                            <Grid.Column>
                              <LabelColor name="Personnel">
                                {this.getTextOrNA(d["health_job"])}
                              </LabelColor>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                        <Divider />
                      </div>
                    ))}
                </Grid>
              </Segment>
            )}

            {activeItem === "historique" && (
              <Segment className="content-grid--content">
                <Grid>
                  <Grid.Row centered className="content--health__header">
                    {this.state.data && this.state.data.histo ? (
                      ""
                    ) : (
                      <h3>Aucun historique</h3>
                    )}
                  </Grid.Row>
                  {typeof this.state.data["histo"] === "object" &&
                    Object.values(this.state.data.histo).map(d => (
                      <div className="grid-wrapper" key={Math.random()}>
                        <Grid>
                          <Grid.Row columns={3}>
                            <Grid.Column>
                              <LabelColor name="Taux d'incapacité">
                                {this.getTextOrNA(d["incapacity"])}
                              </LabelColor>
                            </Grid.Column>
                            <Grid.Column>
                              <LabelColor name="QI">
                                {this.getTextOrNA(d["qi"])}
                              </LabelColor>
                            </Grid.Column>
                            <Grid.Column>
                              <LabelColor name="Pathologie">
                                {this.getTextOrNA(d["pathology__name"])}
                              </LabelColor>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row columns={3}>
                            <Grid.Column>
                              <LabelColor name="Année scolaire">
                                {this.getTextOrNA(d["scolary_year"])}
                              </LabelColor>
                            </Grid.Column>
                            <Grid.Column>
                              <LabelColor name="Déf. principale">
                                {this.getTextOrNA(d["main_deficiency__name"])}
                              </LabelColor>
                            </Grid.Column>
                            <Grid.Column>
                              <LabelColor name="Déf. associée">
                                {this.getTextOrNA(d["link_deficiency__name"])}
                              </LabelColor>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                        <Divider />
                      </div>
                    ))}
                </Grid>
              </Segment>
            )}
            {activeItem === "document" && (
              <Segment className="content-grid--content">
                <FolderViewer
                  slug="doc-health"
                  folder="Santé"
                  resetView={() => this.resetView()}
                />
              </Segment>
            )}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

Health.contextType = CustomerContext;

export default Health;
