import axios from "axios";
import FolderViewer from "component/folderViewer/folderViewer";
import { CustomerContext } from "customer-context.js";
import _ld from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  Label,
  Menu,
  Segment
} from "semantic-ui-react";
import HomeEmpty from "../homeEmpty/homeEmpty";
import LabelColor from "../labelColor/labelColor";
import "./civilStatus.scss";
import profil from "./profil.png";

class CivilStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: "civil",
      data: [],
      customer_id: 0,
      setupData: false
    };
  }

  componentDidMount() {
    this.setState({
      customer_id: this.context.customer_id
    });
  }

  componentWillUpdate(prevState, nextState) {
    if (prevState.customer_id !== this.context.customer_id) {
      this.setState({
        setupData: false
      });
    }
  }

  getUserData = () => {
    let customer_id = this.context.customer_id;
    if (customer_id > 0) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/${this.state.activeItem}/${customer_id}`
        )
        .then(response => {
          if (!_ld.isEmpty(response.data)) {
            this.setState({ data: response.data, setupData: true });
          } else {
            this.setState({ data: [], setupData: true });
          }
        });
    }
  };

  handleClick = (url, itemName) => {
    this.context.resetCurrentView(false);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${url}/${this.context.customer_id}`
      )
      .then(response => {
        this.setState({ data: response.data, activeItem: itemName });
      });
  };

  getTextOrNA = text => {
    return text === null || text === "" || text === undefined ? "NA" : text;
  };

  getTextOrEmpty = text => {
    return text === null || text === "" || text === undefined ? "" : text;
  };

  getDateValidOrNA = (date, i) => {
    if (date !== undefined || date !== null) {
      if (moment(date).isValid()) {
        return moment(date).format("DD/MM/YYYY");
      }
    }

    return "NA";
  };

  getLinkText = data => {
    if (String(data).indexOf("/") > -1) {
      return String(data)
        .split("/")
        .slice(-1);
    } else {
      return "Aucun fichier";
    }
  };

  getResponsable = field => {
    return String(field).split("_")[1] === "f"
      ? "Père"
      : String(field).split("_")[1] === "m"
      ? "Mère"
      : "Autre";
  };

  displayVille = (cp, name) => {
    var displayContent =
      this.getTextOrEmpty(cp) + " " + this.getTextOrEmpty(name);
    return displayContent === " " ? "NA" : displayContent;
  };

  resetView = () => {
    this.setState({
      activeItem: "civil"
    });
  };

  render() {
    const { activeItem, data } = this.state;
    if (this.context.customer_id === 0) {
      if (this.context.company_id > 0) {
        return <HomeEmpty />;
      }
      return <Redirect to="/" />;
    } else if (!this.state.setupData) {
      this.getUserData();
    }
    const FILER_URL = `${process.env.REACT_APP_FILER_URL}`;

    return (
      <Grid className="content-grid content-grid--menu" padded={true}>
        <Grid.Column width={4}>
          <Menu fluid vertical tabular id="menu-vertical">
            {this.context.user.groups.indexOf("etat-civil") > -1 && (
              <Menu.Item
                name="etatCivil"
                active={activeItem === "civil"}
                onClick={() => this.handleClick("civil", "civil")}
                icon={`user circle`}
                className="subMenu--icon"
              />
            )}
            {this.context.user.groups.indexOf("atelier") > -1 &&
              this.context.user.app === "ESAT" && (
                <Menu.Item
                  name="atelier"
                  active={activeItem === "atelier"}
                  className="subMenu--icon"
                  icon={`industry`}
                  onClick={() => this.handleClick("atelier", "atelier")}
                />
              )}
            {this.context.user.groups.indexOf("administratif") > -1 && (
              <Menu.Item
                name="administratif"
                active={activeItem === "adminis"}
                icon={`warehouse`}
                className="subMenu--icon"
                onClick={() => this.handleClick("adminis", "adminis")}
              />
            )}
            {this.context.user.groups.indexOf("orientation") > -1 && (
              <Menu.Item
                name="orientationMDA"
                active={activeItem === "orientation"}
                icon={`travel`}
                className="subMenu--icon"
                onClick={() => this.handleClick("orientation", "orientation")}
              />
            )}

            {this.context.user.groups.indexOf("ase") > -1 && (
              <Menu.Item
                name="suiviASE/SAVS"
                active={activeItem === "ase"}
                icon={`child`}
                className="subMenu--icon"
                onClick={() => this.handleClick("ase", "ase")}
              />
            )}
            {this.context.user.groups.indexOf("resp") > -1 && (
              <Menu.Item
                name="responsable"
                active={activeItem === "resp"}
                icon={`linkify`}
                className="subMenu--icon"
                onClick={() => this.handleClick("resp", "resp")}
              />
            )}
            {this.context.user.groups.indexOf("social") > -1 && (
              <Menu.Item
                name="protectionSociale"
                active={activeItem === "social"}
                icon={`shield`}
                className="subMenu--icon"
                onClick={() => this.handleClick("social", "social")}
              />
            )}
            {this.context.user.groups.indexOf("conseil-dep") > -1 && (
              <Menu.Item
                name="conseilDepartement"
                active={activeItem === "dep"}
                icon={`group`}
                className="subMenu--icon"
                onClick={() => this.handleClick("dep", "dep")}
              />
            )}
            {this.context.user.groups.indexOf("parcours") > -1 && (
              <Menu.Item
                name="parcoursUsager"
                active={activeItem === "parcours"}
                icon={`th`}
                className="subMenu--icon"
                onClick={() => this.handleClick("parcours", "parcours")}
              />
            )}
            {this.context.user.groups.indexOf("current") > -1 && (
              <Menu.Item
                name="orientation"
                active={activeItem === "current"}
                icon={`time`}
                className="subMenu--icon"
                onClick={() => this.handleClick("current", "current")}
              />
            )}
            {this.context.user.groups.indexOf("doc-administratif") > -1 && (
              <Menu.Item
                name="document"
                active={activeItem === "doc-administratif"}
                icon={`tasks`}
                className="subMenu--icon"
                onClick={() =>
                  this.handleClick("doc-administratif", "doc-administratif")
                }
              />
            )}
          </Menu>
        </Grid.Column>

        <Grid.Column stretched width={12}>
          {activeItem === "civil" && (
            <Segment className="content-grid--content">
              <Grid columns={2}>
                <Grid.Column>
                  <LabelColor name="Téléphone">{data["phone"]}</LabelColor>
                  <Divider />
                  <Grid>
                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <LabelColor name="Adresse">
                          {data["address"]}
                        </LabelColor>
                      </Grid.Column>
                      <Grid.Column>
                        <LabelColor name="Date de naissance">
                          {this.getDateValidOrNA(data["birthday"])}
                        </LabelColor>
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <LabelColor name="Code postal">
                          {String(data["postal_code"]).padStart(5, "0")}
                        </LabelColor>
                      </Grid.Column>
                      <Grid.Column>
                        <LabelColor name="Age">
                          {moment().diff(
                            moment(data["birthday"], "YYYY-MM-DD"),
                            "years"
                          )}{" "}
                          ans
                        </LabelColor>
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <LabelColor name="Ville">{data["city"]}</LabelColor>
                      </Grid.Column>
                      <Grid.Column>
                        <LabelColor name="Sexe">{data["sexe"]}</LabelColor>
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <LabelColor name="Famille d'accueil">
                          {this.getTextOrNA(data["family_welcome"])}
                        </LabelColor>
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <LabelColor name="Distance géographique">
                          {data["km"]}
                        </LabelColor>
                      </Grid.Column>
                    </Grid.Row>

                    <Divider />

                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <LabelColor name="Lieu de naissance">
                          {String(data["postal_code_birthday"]).padStart(
                            5,
                            "0"
                          )}{" "}
                          {data["city_birthday"]}
                        </LabelColor>
                      </Grid.Column>
                      <Grid.Column>
                        <LabelColor name="Pays de naissance">
                          {data["country_birthday"]}
                        </LabelColor>
                      </Grid.Column>
                    </Grid.Row>

                    <Divider />

                    <Grid.Row
                      columns={2}
                      className={
                        this.context.user.app === "ESAT"
                          ? ""
                          : "content-grid--content__hidden"
                      }
                    >
                      <Grid.Column>
                        <LabelColor name="N° chambre">
                          {this.getTextOrNA(data["room"])}
                        </LabelColor>
                      </Grid.Column>
                      <Grid.Column>
                        <LabelColor name="Transport">
                          {this.getTextOrNA(data["drive__name"])}
                        </LabelColor>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>

                <Grid.Column>
                  <Label attached="top right">
                    {data["first_name"]} {data["last_name"]}
                  </Label>
                  <Image
                    src={
                      data["document__file"] !== null &&
                      data["document__file"] !== undefined
                        ? `${FILER_URL}/${data["document__file"]}`
                        : profil
                    }
                    floated="right"
                    size="medium"
                  />
                </Grid.Column>
              </Grid>
            </Segment>
          )}
          {activeItem === "atelier" && this.context.user.app === "ESAT" && (
            <Segment className="content-grid--content">
              <Header
                textAlign="center"
                className={
                  data.length === 0
                    ? "content-grid--content-no-data"
                    : "content-grid--content__hidden"
                }
              >
                Aucune données sur la partie Atelier
              </Header>
              {data.length > 0 &&
                Object.values(data).map(d => (
                  <div key={Math.random()}>
                    <Grid columns={3} padded>
                      <Grid.Column columns={1}>
                        <LabelColor name="Type de contrat">
                          {this.getTextOrNA(d["type_contract"])}
                        </LabelColor>
                      </Grid.Column>
                      <Grid.Column columns={1}>
                        <LabelColor name="Activité">
                          {this.getTextOrNA(d["activity"])}
                        </LabelColor>
                      </Grid.Column>
                      <Grid.Row columns={3}>
                        <Grid.Column>
                          <LabelColor name="Date de début">
                            {this.getTextOrNA(d["start_date"])}
                          </LabelColor>
                        </Grid.Column>
                        <Grid.Column>
                          <LabelColor name="Date de fin">
                            {this.getTextOrNA(d["end_date"])}
                          </LabelColor>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <Grid columns={3} padded>
                      <Grid.Column>
                        <LabelColor name="Commentaire">
                          {this.getTextOrNA(d["comment"])}
                        </LabelColor>
                      </Grid.Column>
                    </Grid>
                    <Divider />
                  </div>
                ))}
            </Segment>
          )}

          {activeItem === "adminis" && (
            <Segment className="content-grid--content">
              <Grid padded columns={1}>
                <Grid.Row>
                  <Grid.Column>
                    <LabelColor name="Allocation">{data["alloc"]}</LabelColor>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <LabelColor name="N° Secu">
                      {data["social_secu"]}
                      {", "}
                      {this.getTextOrEmpty(data["social_secu_orga__name"])}
                    </LabelColor>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row
                  className={
                    this.context.user.groups &&
                    this.context.user.groups.indexOf("ESAT") > -1
                      ? "content-grid--content"
                      : "content-grid--content__hidden"
                  }
                >
                  <Grid.Column>
                    <LabelColor name="Mutuelle">
                      {this.getTextOrNA(data["mutuelle"])}
                    </LabelColor>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Column>
                  <LabelColor name="N° CAF">
                    {this.getTextOrNA(data["caf"])}
                  </LabelColor>
                </Grid.Column>
                <Grid.Column>
                  <LabelColor name="IBAN">
                    {this.getTextOrNA(data["iban"])}
                  </LabelColor>
                </Grid.Column>

                <Grid.Column>
                  <LabelColor name="Date expiration CMU">
                    {this.getDateValidOrNA(data["cmu_expiration"])}
                  </LabelColor>
                </Grid.Column>

                <Grid.Column
                  className={
                    this.context.user.groups &&
                    this.context.user.groups.indexOf("ESAT") > -1
                      ? "content-grid--content"
                      : "content-grid--content__hidden"
                  }
                >
                  <LabelColor name="Date expiration CI">
                    {this.getTextOrNA(data["ci_expiration"])}
                  </LabelColor>
                </Grid.Column>
                <Grid.Column
                  className={
                    this.context.user.groups &&
                    this.context.user.groups.indexOf("ESAT") > -1
                      ? "content-grid--content"
                      : "content-grid--content__hidden"
                  }
                >
                  <LabelColor name="Date expiration CMI">
                    {this.getTextOrNA(data["cmi_expiration"])}
                  </LabelColor>
                </Grid.Column>
              </Grid>
            </Segment>
          )}
          {activeItem === "orientation" && (
            <Segment className="content-grid--content">
              <Grid padded columns={2}>
                <Grid.Row>
                  <Grid.Column>
                    <LabelColor name="N° dossier">
                      {this.getTextOrNA(data["orientation_dossier_num"])}
                    </LabelColor>
                  </Grid.Column>
                  <Grid.Column>
                    <LabelColor name="Département">
                      {this.getTextOrNA(data["orientation_dep"])}
                    </LabelColor>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <LabelColor name="Date d'effet">
                      {this.getDateValidOrNA(data["orientation_eff_date"])}
                    </LabelColor>
                  </Grid.Column>
                  <Grid.Column>
                    <LabelColor name="Date de fin">
                      {this.getDateValidOrNA(data["orientation_end_date"])}
                    </LabelColor>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <LabelColor name="Type">
                      {this.getTextOrNA(data["orientation_cat"])}
                    </LabelColor>
                  </Grid.Column>
                  <Grid.Column>
                    <LabelColor name="Date">
                      {data["orientation_cat_date"] === undefined
                        ? "NA"
                        : this.getDateValidOrNA(["orientation_cat_date"])}
                    </LabelColor>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <LabelColor name="Commentaire">
                      {this.getTextOrNA(data["orientation_comment"])}
                    </LabelColor>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <a
                      className={
                        data["orientation_doc_out__file"] !== undefined
                          ? "content-grid--content"
                          : "content-grid--content__hidden"
                      }
                      href={data["orientation_doc_out__file"]}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LabelColor name="Document de sortie">
                        <Icon name="file outline" />
                        {String(data["orientation_doc_out__file"])
                          .split("/")
                          .slice(-1)}
                      </LabelColor>
                    </a>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          )}

          {activeItem === "ase" && (
            <Segment className="content-grid--content">
              <Grid columns={2} padded>
                <Grid.Column columns={1}>
                  <LabelColor name="Service">
                    {this.getTextOrNA(data["ase_service"])}
                  </LabelColor>
                </Grid.Column>

                <Grid.Row columns={3}>
                  <Grid.Column>
                    <LabelColor name="Adresse">
                      {this.getTextOrNA(data["ase_address"])}
                    </LabelColor>
                  </Grid.Column>
                  <Grid.Column>
                    <LabelColor name="Code postal">
                      {this.getTextOrNA(data["ase_postal_code"])}
                    </LabelColor>
                  </Grid.Column>
                  <Grid.Column>
                    <LabelColor name="Ville">
                      {this.getTextOrNA(data["ase_city"])}
                    </LabelColor>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Column columns={1}>
                  <LabelColor name="Référent">
                    {this.getTextOrNA(data["ase_referent_name"])}
                  </LabelColor>
                </Grid.Column>
                <Grid.Column columns={1}>
                  <LabelColor name="Mail">
                    {this.getTextOrNA(data["ase_mail"])}
                  </LabelColor>
                </Grid.Column>
                <Grid.Column columns={1}>
                  <LabelColor name="Tél.">
                    {this.getTextOrNA(data["ase_phone"])}
                  </LabelColor>
                </Grid.Column>
              </Grid>
            </Segment>
          )}

          {activeItem === "resp" && (
            <Segment className="content-grid--content">
              {data.length > 0 &&
                data.map((value, index) => (
                  <Grid columns={2} key={Math.random()}>
                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <LabelColor name="Responsable">
                          {this.getResponsable(
                            Object.keys(data[index]).sort()[6]
                          )}{" "}
                          {data[index][Object.keys(data[index]).sort()[6]] ===
                          true ? (
                            <Icon size="small" color="green" name="check" />
                          ) : (
                            <Icon size="small" color="red" name="dont" />
                          )}
                        </LabelColor>
                      </Grid.Column>
                      <Grid.Column
                        className={
                          this.getResponsable(
                            Object.keys(data[index]).sort()[6]
                          ) === "Autre"
                            ? ""
                            : "content-grid--content__hidden"
                        }
                      >
                        <LabelColor name="Famille accueil">
                          {this.getTextOrNA(data[index]["link"])}
                        </LabelColor>
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <LabelColor name="Prénom">
                          {this.getTextOrNA(
                            data[index][Object.keys(data[index]).sort()[3]]
                          )}
                        </LabelColor>
                      </Grid.Column>
                      <Grid.Column>
                        <LabelColor name="Nom">
                          {this.getTextOrNA(
                            data[index][Object.keys(data[index]).sort()[5]]
                          )}
                        </LabelColor>
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <LabelColor name="Tel">
                          {this.getTextOrNA(
                            data[index][Object.keys(data[index]).sort()[9]]
                          )}
                        </LabelColor>
                      </Grid.Column>
                      <Grid.Column>
                        <LabelColor name="Mail">
                          {this.getTextOrNA(
                            data[index][Object.keys(data[index]).sort()[8]]
                          )}
                        </LabelColor>
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <LabelColor name="Adresse">
                          {this.getTextOrNA(
                            data[index][Object.keys(data[index]).sort()[0]]
                          )}
                        </LabelColor>
                      </Grid.Column>
                      <Grid.Column>
                        <LabelColor name="Ville">
                          {this.displayVille(
                            data[index][Object.keys(data[index]).sort()[10]],
                            data[index][Object.keys(data[index]).sort()[2]]
                          )}
                        </LabelColor>
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={2}>
                      <Grid.Column
                        className={
                          this.getResponsable(
                            Object.keys(data[index]).sort()[6]
                          ) !== "Autre"
                            ? ""
                            : "content-grid--content__hidden"
                        }
                      >
                        <LabelColor name="Activité professionnelle">
                          {this.getTextOrNA(
                            data[index][Object.keys(data[index]).sort()[4]]
                          )}
                        </LabelColor>
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={2}>
                      <Grid.Column
                        className={
                          this.getResponsable(
                            Object.keys(data[index]).sort()[6]
                          ) !== "Autre"
                            ? ""
                            : "content-grid--content__hidden"
                        }
                      >
                        <LabelColor name="Anniversaire">
                          {this.getDateValidOrNA(
                            data[index][Object.keys(data[index]).sort()[1]]
                          )}
                        </LabelColor>
                      </Grid.Column>
                    </Grid.Row>

                    <Divider />
                  </Grid>
                ))}
            </Segment>
          )}
          {activeItem === "social" && (
            <Segment className="content-grid--content">
              <Grid padded columns={2}>
                <Grid.Row>
                  <Grid.Column>
                    <LabelColor name="Type">
                      {this.getTextOrNA(data["social_type"])}
                    </LabelColor>
                  </Grid.Column>
                  <Grid.Column>
                    <LabelColor name="Status">
                      {this.getTextOrNA(data["social_status"])}
                    </LabelColor>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <LabelColor name="Nom">
                      {this.getTextOrNA(data["social_last_name"])}
                    </LabelColor>
                  </Grid.Column>
                  <Grid.Column>
                    <LabelColor name="Prénom">
                      {this.getTextOrNA(data["social_first_name"])}
                    </LabelColor>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <LabelColor name="Service">
                      {this.getTextOrNA(data["social_service"])}
                    </LabelColor>
                  </Grid.Column>
                  <Grid.Column>
                    <LabelColor name="Tel.">
                      {this.getTextOrNA(data["social_phone"])}
                    </LabelColor>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <LabelColor name="Adresse">
                      {this.getTextOrNA(data["social_address"])}
                    </LabelColor>
                  </Grid.Column>
                  <Grid.Column>
                    <LabelColor name="CP">
                      {(data["social_postal_code"] !== null
                        ? this.getTextOrEmpty(
                            String(data["social_postal_code"]).padStart(5, "0")
                          )
                        : "") +
                        " " +
                        this.getTextOrNA(data["social_city"])}
                    </LabelColor>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          )}
          {activeItem === "dep" && (
            <Segment className="content-grid--content">
              <Grid columns={3} padded>
                <Grid.Column columns={1}>
                  <LabelColor name="N° Dossier">
                    {this.getTextOrNA(data["zip_folder_num"])}
                  </LabelColor>
                </Grid.Column>
                <Grid.Row>
                  <Grid.Column columns={1}>
                    <LabelColor name="Date d'effet">
                      {this.getDateValidOrNA(data["zip_apply_date"])}
                    </LabelColor>
                  </Grid.Column>
                  <Grid.Column columns={1}>
                    <LabelColor name="Date de fin">
                      {this.getDateValidOrNA(data["zip_end_date"])}
                    </LabelColor>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Grid columns={3} padded>
                <Grid.Column columns={1}>
                  <LabelColor name="Référent">
                    {this.getTextOrNA(data["zip_referent_name"])}
                  </LabelColor>
                </Grid.Column>
                <Grid.Row>
                  <Grid.Column columns={1}>
                    <LabelColor name="Tel.">
                      {this.getTextOrNA(data["zip_tel"])}
                    </LabelColor>
                  </Grid.Column>
                  <Grid.Column columns={1}>
                    <LabelColor name="Mail">
                      {this.getTextOrNA(data["zip_mail"])}
                    </LabelColor>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Grid columns={3} padded>
                <Grid.Column columns={1}>
                  <LabelColor name="Adresse">
                    {this.getTextOrNA(data["zip_address"])}
                  </LabelColor>
                </Grid.Column>
                <Grid.Row>
                  <Grid.Column columns={1}>
                    <LabelColor name="Code postal">
                      {this.getTextOrNA(data["zip_postal_code"])}
                    </LabelColor>
                  </Grid.Column>
                  <Grid.Column columns={1}>
                    <LabelColor name="Ville">
                      {this.getTextOrNA(data["zip_city"])}
                    </LabelColor>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          )}

          {activeItem === "parcours" && (
            <Segment className="content-grid--content">
              {data[0].length > 0 && (
                <Grid columns={2} key={Math.random()}>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <LabelColor name="Date d'entrée">
                        {this.getDateValidOrNA(data[0][0].way_now_in_date)}
                      </LabelColor>
                    </Grid.Column>
                    <Grid.Column>
                      <LabelColor name="Date de sortie">
                        {this.getDateValidOrNA(data[0][0].way_now_out_date)}
                      </LabelColor>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <LabelColor name="Type">
                        {this.getTextOrNA(data[0][0].way_now_type)}
                      </LabelColor>
                    </Grid.Column>
                    <Grid.Column>
                      <LabelColor name="Période d'essai">
                        {data[0][0].way_now_try_period ? "Oui" : "Non"}
                      </LabelColor>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )}
              {data[1].length > 0 ? (
                <h2 className="period__history-title">Historique</h2>
              ) : (
                <h2 className="period__history-title">Aucun historique</h2>
              )}
              {data[1].length > 0 &&
                data[1].map((value, index) => (
                  <Grid columns={2} key={Math.random()}>
                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <LabelColor name="Type">
                          {this.getTextOrNA(
                            data[1][index][
                              Object.keys(data[1][index]).sort()[9]
                            ]
                          )}
                        </LabelColor>
                      </Grid.Column>
                      <Grid.Column>
                        <LabelColor name="Nom">
                          {this.getTextOrNA(
                            data[1][index][
                              Object.keys(data[1][index]).sort()[4]
                            ]
                          )}
                        </LabelColor>
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <LabelColor name="Service">
                          {this.getTextOrNA(
                            data[1][index][
                              Object.keys(data[1][index]).sort()[8]
                            ]
                          )}
                        </LabelColor>
                      </Grid.Column>
                      <Grid.Column>
                        <LabelColor name="Fonction">
                          {this.getTextOrNA(
                            data[1][index][
                              Object.keys(data[1][index]).sort()[2]
                            ]
                          )}
                        </LabelColor>
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <LabelColor name="Adresse">
                          {this.getTextOrNA(
                            data[1][index][
                              Object.keys(data[1][index]).sort()[0]
                            ]
                          )}
                        </LabelColor>
                      </Grid.Column>
                      <Grid.Column>
                        <LabelColor name="Code postal">
                          {String(
                            data[1][index][
                              Object.keys(data[1][index]).sort()[6]
                            ]
                          ).padStart(5, "0")}
                        </LabelColor>
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <LabelColor name="Ville">
                          {this.getTextOrNA(
                            data[1][index][
                              Object.keys(data[1][index]).sort()[1]
                            ]
                          )}
                        </LabelColor>
                      </Grid.Column>
                      <Grid.Column>
                        <LabelColor name="Date d'entrée">
                          {this.getDateValidOrNA(
                            data[1][index][
                              Object.keys(data[1][index]).sort()[3]
                            ]
                          )}
                        </LabelColor>
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <LabelColor name="Date de sortie">
                          {this.getDateValidOrNA(
                            data[1][index][
                              Object.keys(data[1][index]).sort()[5]
                            ]
                          )}
                        </LabelColor>
                      </Grid.Column>
                      <Grid.Column>
                        <LabelColor name="Raison">
                          {this.getTextOrNA(
                            data[1][index][
                              Object.keys(data[1][index]).sort()[7]
                            ]
                          )}
                        </LabelColor>
                      </Grid.Column>
                    </Grid.Row>
                    <Divider />
                  </Grid>
                ))}
            </Segment>
          )}

          {activeItem === "current" && (
            <Segment className="content-grid--content">
              <Grid columns={2}>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <LabelColor name="Type">
                      {this.getTextOrNA(data["way_now_type"])}
                    </LabelColor>
                  </Grid.Column>
                  <Grid.Column>
                    <LabelColor name="Période d'essai">
                      {data["way_now_try_period"] ? "Oui" : "Non"}
                    </LabelColor>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <LabelColor name="Date d'entrée">
                      {this.getDateValidOrNA(data["way_now_in_date"])}
                    </LabelColor>
                  </Grid.Column>
                  <Grid.Column>
                    <LabelColor name="Date de sortie">
                      {this.getDateValidOrNA(data["way_now_out_date"])}
                    </LabelColor>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <LabelColor name="Date d'archive">
                      {this.getDateValidOrNA(data["way_now_archive_date"])}
                    </LabelColor>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <LabelColor name="Age d'entrée">
                      {this.getTextOrNA(data["age_entrance"])}
                      {" ans"}
                    </LabelColor>
                  </Grid.Column>
                  <Grid.Column>
                    <LabelColor name="Présence dans l'établissement">
                      {this.getTextOrNA(data["nb_day_in"])}
                      {" jours"}
                    </LabelColor>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          )}

          {activeItem === "doc-administratif" && (
            <Segment className="content-grid--content">
              <FolderViewer
                slug="doc-administratif"
                folder="Administratif"
                resetView={() => this.resetView()}
              />
            </Segment>
          )}
        </Grid.Column>
      </Grid>
    );
  }
}

CivilStatus.contextType = CustomerContext;

export default CivilStatus;
