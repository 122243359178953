import axios from "axios";
import { CustomerContext } from "customer-context.js";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Button, Icon, Loader, Segment } from "semantic-ui-react";
import "./folderViewer.scss";

class FolderViewer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      folder: { folderId: -2 } // init : -2, no folder : -1, folder : value > 0
    };
  }

  componentDidMount() {
    // Get current costumer's folder id
    this.getUserData();
  }

  getUserData = () => {
    let customer_id = this.context.customer_id;
    if (customer_id > 0) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/${this.props.slug}/${customer_id}`
        )
        .then(response => {
          this.setState({ folder: response.data });
        });
    }
  };

  // Set iframe DOM
  loadFrame = () => {
    var iframe = document.getElementsByClassName("iframe-doc")[0];
    if (iframe) {
      iframe.contentWindow.onunload = () => {
        iframe.style.visibility = "hidden";
      };
      iframe.onload = () => {
        const grp_header = window.frames[0].document.getElementById(
          "grp-header"
        );

        if (grp_header) {
          grp_header.remove();
        }

        const grp_content_title = window.frames[0].document.getElementById(
          "grp-content-title"
        );

        if (grp_content_title) {
          grp_content_title.remove();
        }

        const grp_content = window.frames[0].document.getElementById(
          "grp-content"
        );

        if (grp_content) {
          grp_content.style.padding = 0;
        }

        const filter_files = window.frames[0].document.getElementsByClassName(
          "filter-files-container js-filter-files-container"
        )[0];

        if (filter_files) {
          filter_files.remove();
        }

        const actions_wrapper = window.frames[0].document.getElementsByClassName(
          "actions-wrapper"
        )[0];

        if (actions_wrapper) {
          actions_wrapper.remove();
        }

        const nav_btn_wrapper = window.frames[0].document.getElementsByClassName(
          "navigator-button-wrapper"
        )[0];

        if (nav_btn_wrapper) {
          nav_btn_wrapper.remove();
        }

        const file_nav_dd = window.frames[0].document.getElementsByClassName(
          "filer-navigator-breadcrumbs-dropdown-container filer-dropdown-container"
        )[0];

        if (file_nav_dd) {
          file_nav_dd.remove();
        }

        const emptyFolder = window.frames[0].document.getElementsByClassName(
          "no-files"
        )[0];

        if (emptyFolder) {
          emptyFolder.remove();
        }

        const filder_dd_container = window.frames[0].document.getElementsByClassName(
          "filer-dropdown-container filer-dropdown-container-down"
        )[0];

        if (filder_dd_container) {
          filder_dd_container.remove();
        }

        const grp_warning = window.frames[0].document.getElementsByClassName(
          "grp-warning"
        )[0];

        if (grp_warning) {
          grp_warning.remove();
        }

        const grp_messagelist = window.frames[0].document.getElementsByClassName(
          "grp-messagelist"
        )[0];

        if (grp_messagelist) {
          grp_messagelist.remove();
        }

        const htmlElement = window.frames[0].document.getElementsByClassName(
          "action-button"
        );

        for (let el of htmlElement) {
          if (!el.title.includes("Télécharger")) {
            el.style.display = "none";
          }
        }

        const docLinks = window.frames[0].document.getElementsByTagName(
          "strong"
        );

        for (let el of docLinks) {
          el.style.pointerEvents = "none";
        }

        iframe.style.visibility = "visible";

        this.setState({
          loading: false
        });
      };
    }
  };

  // Return to previous page in iframe
  goBack = () => {
    const frame = document.getElementsByClassName("iframe-doc")[0];

    const root_folder = window.frames[0].document.getElementsByClassName(
      "navigator-breadcrumbs-folder-name-inner"
    )[0];

    if (root_folder.innerHTML.replace(/\s/g, "") !== this.props.folder) {
      frame.contentWindow.history.back();
    }
  };

  render() {
    if (this.context.customer_id === 0) {
      return <Redirect to="/" />;
    }
    // Redirect to /civil when user pick an other costumer
    if (this.context.redirectToCivil) {
      this.context.goToCivil(false);
      return <Redirect to="/civil" />;
    }
    if (
      this.context.resetView &&
      document.location.href !== `${process.env.REACT_APP_BASE_FRONT}/technical`
    ) {
      this.context.resetCurrentView(false);
      this.props.resetView();
    }

    return (
      <div className="folderViewer">
        {this.state.loading && this.state.folder.folderId !== -1 && (
          <Segment className="folderViewer__loader">
            <Loader active />
          </Segment>
        )}
        {this.state.folder.folderId !== -1 ? (
          <div>
            <Button onClick={() => this.goBack()} id="back-btn">
              <Icon name="chevron left" size="large" color="grey" />
            </Button>
            <iframe
              title="cotumer doc"
              className="iframe-doc"
              onLoad={this.loadFrame()}
              style={{ visibility: "hidden" }}
              src={`${process.env.REACT_APP_API_URL}/admin/filer/folder/${this.state.folder.folderId}/list`}
            />
          </div>
        ) : (
          <h2 className="folderViewer__no-folder">Aucun dossier disponible</h2>
        )}
      </div>
    );
  }
}

FolderViewer.contextType = CustomerContext;
export default FolderViewer;
