import React from "react";

export const CustomerContext = React.createContext({
  company_id: 0,
  customer_id: 0,
  redirectToCivil: false,
  resetView: false,
  toggleCustomer: () => {},
  toggleCompany: () => {},
  goToCivil: () => {},
  resetCurrentView: () => {}
});
