import { CustomerContext } from "customer-context.js";
import React, { PureComponent } from "react";
import { Grid } from "semantic-ui-react";
import arrow from "./arrow.png";
import "./homeEmpty.scss";

class HomeEmpty extends PureComponent {
  displayName = () => {
    if (
      this.context.user.groups &&
      this.context.user.groups.indexOf("ESAT") > -1
    ) {
      return "de l'usager";
    } else {
      return "du résident";
    }
  };

  render() {
    return (
      <Grid container textAlign="center">
        <Grid.Row>
          <Grid.Column width={8}>
            <div className={"home-search-text"}>
              Veuillez saisir le nom {this.displayName()}
            </div>
          </Grid.Column>
          <Grid.Column floated="right" width={5}>
            <img className={"home-search"} src={arrow} alt="" />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

HomeEmpty.contextType = CustomerContext;

export default HomeEmpty;
