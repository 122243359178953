import FolderViewer from "component/folderViewer/folderViewer";
import { CustomerContext } from "customer-context.js";
import React, { Component } from "react";

class Technical extends Component {
  render() {
    return (
      <div>
        <FolderViewer slug="doc-technical" folder="Technique" />
      </div>
    );
  }
}

Technical.contextType = CustomerContext;
export default Technical;
