import axios from "axios";
import { CustomerContext } from "customer-context.js";
import _ from "lodash";
import React, { Component } from "react";
import { Search } from "semantic-ui-react";
import profil from "./profil.png";
import "./searchContext.scss";

class SearchContext extends Component {
  componentWillMount() {
    this.resetComponent();

    this.setState({ init: true });
  }

  componentDidUpdate() {
    if (this.state.init && this.context.company_id > 0) {
      this.setState({ init: false });
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/civil/all_customer_id/${this.context.company_id}`
        )
        .then(response => {
          this.autoFocusSearch();
          const data_search = Object.keys(response.data).map(key => ({
            id: response.data[key].id,
            title: `${response.data[key].first_name} ${response.data[key].last_name}`,
            image:
              response.data[key].document__file !== "/s/filer/None"
                ? response.data[key].document__file
                : profil
          }));
          this.setState({ all_customer: data_search });
        });
    }
  }

  autoFocusSearch = () => {
    document.getElementById("ime-search").focus();
  };

  resetComponent = () =>
    this.setState({
      isLoading: false,
      results: [],
      value: ""
    });

  handleResultSelect = (e, { result }) => {
    this.setState({ value: result.title });
    this.context.toggleCustomer(result.id);

    if (
      document.location.href ===
        `${process.env.REACT_APP_BASE_FRONT}/technical` ||
      document.location.href === `${process.env.REACT_APP_BASE_FRONT}/health`
    ) {
      this.context.goToCivil(true);
    } else if (
      document.location.href === `${process.env.REACT_APP_BASE_FRONT}/civil`
    ) {
      this.context.resetCurrentView(true);
    }
  };

  handleSearchChange = (e, { value }) => {
    this.setState({
      isLoading: true,
      value
    });

    setTimeout(() => {
      if (this.state.value.length < 1) return this.resetComponent();

      const re = new RegExp(_.escapeRegExp(this.state.value), "i");
      const isMatch = result => re.test(result.title);

      this.setState({
        isLoading: false,
        results: _.filter(this.state.all_customer, isMatch)
      });
    }, 300);
  };

  render() {
    const { isLoading, value, results } = this.state;

    return (
      <div
        className={
          this.context.company_id === 0
            ? "content--search__hidden"
            : "content--search"
        }
      >
        <Search
          id="ime-search"
          loading={isLoading}
          onResultSelect={this.handleResultSelect}
          onSearchChange={_.debounce(this.handleSearchChange, 500, {
            leading: true
          })}
          results={results}
          value={value}
          noResultsMessage={`Aucun résultat`}
          className={`search--result_not_found`}
          {...this.props}
        />
      </div>
    );
  }
}

SearchContext.contextType = CustomerContext;

export default SearchContext;
