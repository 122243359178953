import { CustomerContext } from "customer-context.js";
import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import SearchContext from "./searchContext";

class SearchResident extends Component {
  render() {
    return (
      <Grid>
        <Grid.Column width={6}>
          <CustomerContext.Consumer>
            {({ context }) => <SearchContext />}
          </CustomerContext.Consumer>
        </Grid.Column>
      </Grid>
    );
  }
}

SearchResident.contextType = CustomerContext;

export default SearchResident;
