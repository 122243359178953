import { CustomerContext } from "customer-context.js";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Card, Grid, Image } from "semantic-ui-react";
import "./company.scss";
import logo_neuville_ime from "./logo_neuville_ime.png";
import logo_sessad from "./logo_sessad.png";
import logo_st_hilaire from "./logo_st_hilaire.jpg";

class Company extends Component {
  constructor(props) {
    super(props);

    this.state = {
      company_name: [],
      company_img: []
    };
  }

  componentDidMount() {
    // To present searchbar display
    this.context.toggleCompany(0);
    // Setting compagny
    setTimeout(() => {
      this.setCompagny(this.context.user.groups);
    }, 250);
  }

  chooseCompany = id => {
    this.context.toggleCompany(id);
  };

  setCompagny = companies => {
    if (companies !== undefined && companies.indexOf("ESAT") > -1) {
      this.setState({
        company_name: ["ESAT"],
        company_img: [logo_st_hilaire]
      });
    } else {
      this.setState({
        company_name: ["IME", "Sessad pro"],
        company_img: [logo_neuville_ime, logo_sessad]
      });
    }
  };

  render() {
    return (
      <Grid columns={6} centered className={`grid-company`}>
        <Grid.Row>
          <Grid.Column>
            <NavLink to="/civil">
              <Card as="div" onClick={() => this.chooseCompany(1)}>
                <Image src={this.state.company_img[0]} wrapped ui={false} />
                <Card.Content className={`company-text`}>
                  {this.state.company_name[0]}
                </Card.Content>
              </Card>
            </NavLink>
          </Grid.Column>
          <Grid.Column
            className={
              this.context.user.groups &&
              this.context.user.groups.indexOf("ESAT") > -1
                ? "content-grid--content__hidden"
                : ""
            }
          >
            <NavLink to="/civil">
              <Card as="div" onClick={() => this.chooseCompany(2)}>
                <Image src={this.state.company_img[1]} wrapped ui={false} />
                <Card.Content className={`company-text`}>
                  {this.state.company_name[1]}
                </Card.Content>
              </Card>
            </NavLink>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

Company.contextType = CustomerContext;

export default Company;
