import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { CustomerContext } from "customer-context.js";
import moment from "moment";
import "moment/locale/fr";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Grid, Icon, Label, Menu, Segment } from "semantic-ui-react";
import "./export.scss";

moment.locale("fr");

class Export extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: "etatCivil",
      dates: [new Date("09/02/2019"), new Date()]
    };
  }

  onDateChange = dates => this.setState({ dates });

  handleClick = itemName => {
    this.setState({ activeItem: itemName });
  };

  render() {
    if (this.context.customer_id === 0) {
      return <Redirect to="/" />;
    }
    const { activeItem } = this.state;

    const startDate = moment(this.state.dates[0], "DD-MM-YYYY")
      .format("YYYY-MM-DD")
      .replace(new RegExp("/", "g"), "-");

    const endDate = moment(this.state.dates[1], "DD-MM-YYYY")
      .format("YYYY-MM-DD")
      .replace(new RegExp("/", "g"), "-");

    return (
      <div className="content--export">
        <Grid className="content-grid-export" padded={true}>
          <Grid.Column width={4}>
            <Menu fluid vertical tabular id="menu-vertical">
              <Menu.Item
                name="etatCivil"
                active={activeItem === "etatCivil"}
                onClick={() => this.handleClick("etatCivil")}
                icon={`user circle`}
                className={"subMenu--icon"}
              />
              <Menu.Item
                name="ficheMedicale"
                active={activeItem === "ficheMedicale"}
                className={"subMenu--icon"}
                icon={`stethoscope`}
                onClick={() => this.handleClick("ficheMedicale")}
              />
            </Menu>
          </Grid.Column>

          <Grid.Column stretched width={12}>
            {activeItem === "etatCivil" && (
              <Segment className="content-grid--content">
                <Grid>
                  <Grid.Row>
                    <Grid.Column className="content--export__date-picker-container">
                      <span>Date d'entrée &nbsp;</span>
                      <DateRangePicker
                        clearIcon={null}
                        locale="fr"
                        className="content--export__date-picker"
                        onChange={this.onDateChange}
                        value={this.state.dates}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Grid>
                  {this.context.user.groups.indexOf("ESAT") === -1 && (
                    <Grid.Row>
                      <Grid.Column>
                        <a
                          href={`${process.env.REACT_APP_API_URL}/export/civil-infos/${startDate}/${endDate}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Label color="red">
                            <Icon name="download" />
                            Tout exporter
                          </Label>
                        </a>
                      </Grid.Column>
                    </Grid.Row>
                  )}
                  <Grid.Row columns={4}>
                    <Grid.Column>
                      <a
                        href={`${process.env.REACT_APP_API_URL}/export/age/${startDate}/${endDate}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Label>
                          <Icon name="download" />
                          Âge
                        </Label>
                      </a>
                    </Grid.Column>
                    <Grid.Column>
                      <a
                        href={`${process.env.REACT_APP_API_URL}/export/zip/${startDate}/${endDate}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Label>
                          <Icon name="download" />
                          Département
                        </Label>
                      </a>
                    </Grid.Column>
                    <Grid.Column>
                      <a
                        href={`${process.env.REACT_APP_API_URL}/export/km/${startDate}/${endDate}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Label>
                          <Icon name="download" />
                          Distances
                        </Label>
                      </a>
                    </Grid.Column>
                    <Grid.Column>
                      <a
                        href={`${process.env.REACT_APP_API_URL}/export/ase/${startDate}/${endDate}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Label>
                          <Icon name="download" />
                          ASE
                        </Label>
                      </a>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row columns={4}>
                    <Grid.Column>
                      <a
                        href={`${process.env.REACT_APP_API_URL}/export/social/${startDate}/${endDate}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Label>
                          <Icon name="download" />
                          Type de protection
                        </Label>
                      </a>
                    </Grid.Column>
                    <Grid.Column>
                      <a
                        href={`${process.env.REACT_APP_API_URL}/export/job-pere/${startDate}/${endDate}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Label>
                          <Icon name="download" />
                          Activité du père
                        </Label>
                      </a>
                    </Grid.Column>
                    <Grid.Column>
                      <a
                        href={`${process.env.REACT_APP_API_URL}/export/job-mere/${startDate}/${endDate}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Label>
                          <Icon name="download" />
                          Activité de la mère
                        </Label>
                      </a>
                    </Grid.Column>
                    <Grid.Column>
                      <a
                        href={`${process.env.REACT_APP_API_URL}/export/orientation/${startDate}/${endDate}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Label>
                          <Icon name="download" />
                          Orientations date de fin
                        </Label>
                      </a>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row columns={4}>
                    <Grid.Column>
                      <a
                        href={`${process.env.REACT_APP_API_URL}/export/establishment-type/${startDate}/${endDate}/${this.context.company_id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Label>
                          <Icon name="download" />
                          Type d'établissement
                        </Label>
                      </a>
                    </Grid.Column>
                    <Grid.Column>
                      <a
                        href={`${process.env.REACT_APP_API_URL}/export/in-date/${startDate}/${endDate}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Label>
                          <Icon name="download" />
                          Dates d'entrées
                        </Label>
                      </a>
                    </Grid.Column>
                    <Grid.Column>
                      <a
                        href={`${process.env.REACT_APP_API_URL}/export/in-age/${startDate}/${endDate}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Label>
                          <Icon name="download" />
                          Âge d'entrée
                        </Label>
                      </a>
                    </Grid.Column>
                    <Grid.Column>
                      <a
                        href={`${process.env.REACT_APP_API_URL}/export/statut/${startDate}/${endDate}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Label>
                          <Icon name="download" />
                          Statut
                        </Label>
                      </a>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={4}>
                    <Grid.Column>
                      <a
                        href={`${process.env.REACT_APP_API_URL}/export/in-date-day/${startDate}/${endDate}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Label>
                          <Icon name="download" />
                          Nombre de jour de présence
                        </Label>
                      </a>
                    </Grid.Column>
                    <Grid.Column>
                      <a
                        href={`${process.env.REACT_APP_API_URL}/export/out-date/${startDate}/${endDate}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Label>
                          <Icon name="download" />
                          Dates de sorties
                        </Label>
                      </a>
                    </Grid.Column>
                    <Grid.Column>
                      <a
                        href={`${process.env.REACT_APP_API_URL}/export/age-out/${startDate}/${endDate}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Label>
                          <Icon name="download" />
                          Âge de sortie
                        </Label>
                      </a>
                    </Grid.Column>
                    <Grid.Column>
                      <a
                        href={`${process.env.REACT_APP_API_URL}/export/orientation-type/${startDate}/${endDate}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Label>
                          <Icon name="download" />
                          Type d'orientation
                        </Label>
                      </a>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={4}>
                    <Grid.Column>
                      <a
                        href={`${process.env.REACT_APP_API_URL}/export/orientation/${startDate}/${endDate}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Label>
                          <Icon name="download" />
                          Choix d'orientation
                        </Label>
                      </a>
                    </Grid.Column>
                    <Grid.Column>
                      <a
                        href={`${process.env.REACT_APP_API_URL}/export/trial-period/${startDate}/${endDate}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Label>
                          <Icon name="download" />
                          Période d'essai
                        </Label>
                      </a>
                    </Grid.Column>
                    <Grid.Column>
                      <a
                        href={`${process.env.REACT_APP_API_URL}/export/sexe/${startDate}/${endDate}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Label>
                          <Icon name="download" />
                          Sexe
                        </Label>
                      </a>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            )}
            {activeItem === "ficheMedicale" && (
              <Segment className="content-grid--content">
                <Grid>
                  <Grid.Row>
                    <Grid.Column className="content--export__date-picker-container">
                      <span>Date d'entrée &nbsp;</span>
                      <DateRangePicker
                        clearIcon={null}
                        locale="fr"
                        className="content--export__date-picker"
                        onChange={this.onDateChange}
                        value={this.state.dates}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>

                <Grid>
                  {this.context.user.groups.indexOf("ESAT") === -1 && (
                    <Grid.Row>
                      <Grid.Column>
                        <a
                          href={`${process.env.REACT_APP_API_URL}/export/health-infos/${startDate}/${endDate}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Label color="red">
                            <Icon name="download" />
                            Tout exporter
                          </Label>
                        </a>
                      </Grid.Column>
                    </Grid.Row>
                  )}

                  <Grid.Row columns={4}>
                    <Grid.Column>
                      <a
                        href={`${process.env.REACT_APP_API_URL}/export/health-incapacity/${startDate}/${endDate}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Label>
                          <Icon name="download" />
                          Taux d'incapacité
                        </Label>
                      </a>
                    </Grid.Column>
                    <Grid.Column>
                      <a
                        href={`${process.env.REACT_APP_API_URL}/export/health-qi/${startDate}/${endDate}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Label>
                          <Icon name="download" />
                          QI
                        </Label>
                      </a>
                    </Grid.Column>
                    <Grid.Column>
                      <a
                        href={`${process.env.REACT_APP_API_URL}/export/health-deficiency/${startDate}/${endDate}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Label>
                          <Icon name="download" />
                          Trouble
                        </Label>
                      </a>
                    </Grid.Column>
                    <Grid.Column>
                      <a
                        href={`${process.env.REACT_APP_API_URL}/export/health-pai/${startDate}/${endDate}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Label>
                          <Icon name="download" />
                          PAI
                        </Label>
                      </a>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={4}>
                    <Grid.Column>
                      <a
                        href={`${process.env.REACT_APP_API_URL}/export/health-psy/${startDate}/${endDate}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Label>
                          <Icon name="download" />
                          Accompagnement Psychologique
                        </Label>
                      </a>
                    </Grid.Column>
                    <Grid.Column>
                      <a
                        href={`${process.env.REACT_APP_API_URL}/export/health-ortho/${startDate}/${endDate}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Label>
                          <Icon name="download" />
                          Accompagnement Orthophonique
                        </Label>
                      </a>
                    </Grid.Column>
                    <Grid.Column>
                      <a
                        href={`${process.env.REACT_APP_API_URL}/export/health-psychomot/${startDate}/${endDate}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Label>
                          <Icon name="download" />
                          Accompagnement Psychomoteur
                        </Label>
                      </a>
                    </Grid.Column>
                    <Grid.Column>
                      <a
                        href={`${process.env.REACT_APP_API_URL}/export/health-main-deficiency/${startDate}/${endDate}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Label>
                          <Icon name="download" />
                          Déficience principale
                        </Label>
                      </a>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={4}>
                    <Grid.Column>
                      <a
                        href={`${process.env.REACT_APP_API_URL}/export/health-secondary-deficiency/${startDate}/${endDate}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Label>
                          <Icon name="download" />
                          Déficience associée
                        </Label>
                      </a>
                    </Grid.Column>
                    <Grid.Column>
                      <a
                        href={`${process.env.REACT_APP_API_URL}/export/health-pathology-deficiency/${startDate}/${endDate}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Label>
                          <Icon name="download" />
                          Pathologies
                        </Label>
                      </a>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            )}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

Export.contextType = CustomerContext;

export default Export;
