import { CustomerContext } from "customer-context.js";
import React, { Component } from "react";
import { Grid, Icon, Image, Label, Rail } from "semantic-ui-react";
import Navbar from "../../component/navbar/navbar";
import Search from "../../component/search/search";
import "./header.css";
import logo_cms from "./logo_cms.png";
import logo_esat from "./logo_st_hilaire.png";

class Header extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return false;
  }

  admin = () => (document.location.href = `${process.env.REACT_APP_LOGIN_URL}`);

  logout = () =>
    (document.location.href = `${process.env.REACT_APP_LOGOUT_URL}`);

  render() {
    return (
      <Grid container>
        <Rail internal position="left">
          <Label
            horizontal
            as="a"
            color="red"
            onClick={this.admin}
            className={
              this.context.user.admin === true
                ? ""
                : "content-grid--content__hidden"
            }
          >
            Administration <Icon name="setting" />
          </Label>
          <Label
            as="a"
            color="blue"
            onClick={this.logout}
            className={
              this.context.user.admin === true
                ? "content-grid--content__hidden"
                : ""
            }
          >
            {this.context.user.name} <Icon name="log out" />
          </Label>
        </Rail>

        <Grid.Row>
          <Grid.Column width={3}>
            <a href="/" alt="Application">
              <Image
                src={
                  this.context.user.groups &&
                  this.context.user.groups.indexOf("ESAT") > -1
                    ? logo_esat
                    : logo_cms
                }
                className="header--logo"
              />
            </a>
          </Grid.Column>
          <Grid.Column floated="right" width={10}>
            {this.context.customer_id !== 0 && <Navbar />}
          </Grid.Column>
          <Grid.Column floated="right" verticalAlign="middle" width={1}>
            <Search />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

Header.contextType = CustomerContext;

export default Header;
